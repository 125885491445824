import axios from "axios";

const instance = axios.create({
 //baseURL: "http://localhost:8000/"
 // baseURL: "https://back-empros.herokuapp.com/"
// baseURL: "http://backend.empros-ek.gr:8879/"
 baseURL: "https://backend.empros-ek.gr/"
});

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance;
