import Vue from 'vue'
import Router from 'vue-router'
import store from "../store/index";
import axios from "../axios-auth";
// Containers
const TheContainer = () => import('@/containers/TheContainer')
const pageRouter = () => import('@/containers/pageRouter')

// Views
//const Dashboard = () => import('@/views/Dashboard')
//const test = () => import('@/views/test')

//const Colors = () => import('@/views/theme/Colors')
const entomologicalData = () => import('@/views/pages/entomologicalData')
const chickensData=()=> import('@/views/pages/chickensData')
const SettlementWestNileVirus = () => import('@/views/pages/SettlementWestNileVirus')
const SettlementWestNileVirusNew = () => import('@/views/pages/SettlementWestNileVirusNew')
const WestNileVirus_Municipality = () => import('@/views/pages/WestNileVirus_Municipality')
const mosq_abun_pred_culex = () => import('@/views/pages/bad')
const mamoth = () => import('@/views/pages/mamoth')
const about = () => import('@/views/pages/about')
const profile = () => import('@/views/pages/profile')
const cases = () => import('@/views/pages/cases')

//const Login = () => import('@/views/pages/login_new')
//const Typography = () => import('@/views/theme/Typography')
/*
//const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/login_new')
//const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
*/
Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: configRoutes()
})

function configRoutes () {
  return [
   
    {
      
      path: '/',
      redirect: '/Entomological-Data',
      name: 'Home',
      component: TheContainer,
      
      children: [
        {
          path: '/about',
          name: 'About',
          component:about,
        },
        {
          path: '/Entomological-Data',
          //redirect: '/Entomological-Data',
          name: 'Entomological Data',
          component:entomologicalData,
          beforeEnter(to, from, next) {
            testAuth(next,to);
          },
        
        },
        {
          path: '/Chickens-Data',
          //redirect: '/Entomological-Data',
          name: 'Backyard chickens',
          component:chickensData,
          beforeEnter (to, from, next) {
            testAuth(next)
            
          }
        },
        {
          path: 'HumanCasesRiskPredictions',
          name: 'Predictions',
          redirect: '/HumanCasesRiskPredictions/WestNileVirus/Settlement',
          component:pageRouter,
          children:[
            {
              path: 'WestNileVirus',
              name: 'WNV Risk predictions',
              redirect: '/HumanCasesRiskPredictions/WestNileVirus/Settlement',
              component:pageRouter,
              children: [
                {
                  path: 'Settlement',
                  name: 'BAR (settlements)',
                  component: SettlementWestNileVirus,
                  beforeEnter (to, from, next) {
                    testAuth(next)
                    
                  }
                },
                {
                  path: 'Settlement-new',
                  name: 'BAR (new)',
                  component: SettlementWestNileVirusNew,
                  beforeEnter (to, from, next) {
                    testAuth(next)
                  }
                },

                {
                  path: 'Municipality',
                  name: 'MIMESIS (municipalities)',
                  component: WestNileVirus_Municipality,
                  beforeEnter (to, from, next) {
                  
                    testAuth(next)
                  }
                },
              ]
              
            },
          ]
        },
        {
          path: 'MosquitoesAbundancePredictions',
          name: "Mosquitoes' Abundance Predictions",
          redirect: '/MosquitoesAbundancePredictions/bad',
          component:pageRouter,
          children:[
            {
              path: 'bad',
              name: 'BAD (settlements)',
              component: mosq_abun_pred_culex,
                  beforeEnter (to, from, next) {
                    testAuth(next)
                    
                  }  
            },
          ]
        },
        {
          path: 'MosquitoesAbundancePredictions',
          name: "Mosquitoes' Abundance Predictions",
          redirect: '/MosquitoesAbundancePredictions/mamoth',
          component:pageRouter,
          children:[
            {
              path: 'mamoth',
              name: 'ΜΑΜΟΤΗ',
              component: mamoth,
                  beforeEnter (to, from, next) {
                    testAuth(next)
                    
                  }  
            },
          ]
        },
        {
          path: "/profile",
          name: "Profile",
          component: profile,
          beforeEnter(to, from, next) {
            testAuth(next);
          }
        },
        {
          path: "/cases",
          name: "Cases",
          component: cases,
          beforeEnter(to, from, next) {
            testAuth(next);
          }
        }
        
        
      ]
    },
    

    
  ]
}

 function testAuth(next) {

    var token = localStorage.getItem("token");
    //console.log(token)
    //console.log(store.state.isLogin)
    axios
      .post("verifytoken/", { token: token })
      .then(()=>{
        //console.log('here')
        store.commit('set', ['isLogin', true])
        axios.get('user',{ headers: { Authorization: 'Bearer ' + token }}).then(function(response){
          //console.log('user',response)
          localStorage.setItem("user", JSON.stringify(response.data));
          next()
        })
        
        
      })
      .catch(() => {
        console.log('login')
        //next()
        //next("/")
       // this.$router.push('/')
        //next('/Entomological-Data')
  
        next()
       // next()
        //store.dispatch('logout')
        store.commit('set', ['isLogin', false])
        //store.dispatch("logout");
        //console.log(err);
      });
  
}


