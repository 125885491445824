/*import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
},


export default new Vuex.Store({
  state,
  mutations
})*/
import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios-auth";
//import globalAxios from 'axios'
import json_en from "../json/language_en.json"
import json_gr from "../json/languege_gr.json"
import routes from "../router/index.js";
//import axios from '../axios-auth'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseURL:'https://gis.empros-ek.gr/',
    idToken: null,
    user: null,
    name: null,
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    isLogin:false,
    showRouter:true,
    alertLogin:false,
    language:'gr',
    json_language:{'en':json_en,'gr':json_gr},
    trap_data:null,
    chicken_data:null,
    modalShow:false,
  },
  mutations: {
    toggleSidebarDesktop (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
      state[variable] = value
      if(variable==='language'){
        
        state.showRouter=false
        setTimeout(() => {state.showRouter= true}, 100);

      }
    },
    storeUser(state, user) {
      state.user = user;
    },
    pagename(state, name) {
      state.name = name;
    }
  },
  actions: {
    login({ commit }, authData) {
      console.log(authData.username)
       axios
        .post("api-token/", {
          username: authData.username,
          password: authData.password
        })
        .then(res => {
          localStorage.setItem("token", res.data.access);
          /*
          if (res.data.access !== null) {
            routes.replace("/Entomological-Data");
          }*/
          //console.log('here')
          axios.get('user',{ headers: { Authorization: 'Bearer ' + res.data.access }}).then(function(response){
            //console.log('user',response)
            localStorage.setItem("user", JSON.stringify(response.data));
            /*if (res.data.access !== null) {
              routes.replace("/");
            }*/
            commit("storeUser", authData.username);
          commit("set", ['isLogin', true]);
          commit("set", ['modalShow', false]);
          //routes.go('/Entomological-Data')
          //routes.go("/")
         commit("set", ['alertLogin', false])
         commit("set", ['showRouter', false])
        
         setTimeout(() => {commit("set", ['showRouter', true])}, 100);
          })
          

              
        })
        .catch(() => {
          commit("set", ['alertLogin', true])
        }
        );
      //commit('storeUser', authData.username)
      //this.$store.state.user=authData.username
    },
    logout({commit}) {
      localStorage.clear()
      commit("set", ['isLogin', false]);
      commit("set", ['showRouter', false])
      
      setTimeout(() => {commit("set", ['showRouter', true])}, 100);
      routes.replace("/")
      //localStorage.removeItem('page_name')
      //routes.replace("/login");
      //location.reload();
    },
    pageName({ commit }, name) {
      //console.log(name)
      commit("pagename", name.name);
      //this.$store.state.user=authData.username
    }
    /*
    login ({commit, dispatch}, authData) {
      axios.post('http://127.0.0.1:8000/api-token/', {
        username: authData.username,
        password: authData.password,
       // returnSecureToken: true
      })
        .then(res => {
          //console.log(res)
          //const now = new Date()
          //const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
          localStorage.setItem('token', res.data.access)
          //localStorage.setItem('retoken', res.data.refresh)
          //context.commit('updateStorage', { access: res.data.access, refresh: response.data.refresh })


          //localStorage.setItem('userId', res.data.localId)
          //localStorage.setItem('expirationDate', expirationDate)
          
          //dispatch('setLogoutTimer', res.data.expiresIn)
          if(res.data.access !== null){ routes.push({ path: '/dashboard' })}
        })
        .catch(error => console.log(error))
    },*/

    //  logout ({commit}) {
    // localStorage.removeItem('expirationDate')
    //localStorage.removeItem('token')
    //localStorage.removeItem('retoken')
    //localStorage.removeItem('username')
    //localStorage.removeItem('page_name')
    //commit('storeUser', null)
    //  localStorage.removeItem('userId')
    //  routes.replace('/')
    // },
    /*fetchUser ({commit}) {

      const token = localStorage.getItem('token')
      const username = localStorage.getItem('username')

      axios.get('http://127.0.0.1:8000/user/'+ username, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          //console.log(response)
          commit('storeUser', response.data)
          // this.$store.state.APIData = response.data
        })
        .catch(err => {
          console.log(err)
        })
    }*/
  },
  getters: {
    user(state) {
      return state.user;
    },
    PageName(state) {
      return state.name;
    },
    isAuthenticated(state) {
      return state.user !== null;
    }
  }
});
